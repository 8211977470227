var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-demo-view", staticStyle: { padding: "1rem" } },
    [
      _c(
        "div",
        {
          staticStyle: {
            background: "#FFFFFF",
            "padding-left": "20px",
            "margin-bottom": "10px",
          },
        },
        [
          _vm.reload
            ? _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.jump },
                  model: {
                    value: _vm.tabIndex,
                    callback: function ($$v) {
                      _vm.tabIndex = $$v
                    },
                    expression: "tabIndex",
                  },
                },
                [
                  _vm.tabs[0].isShow
                    ? _c("el-tab-pane", {
                        attrs: { label: _vm.tabs[0].title, name: "0" },
                      })
                    : _vm._e(),
                  _vm.tabs[1].isShow
                    ? _c("el-tab-pane", {
                        attrs: { label: _vm.tabs[1].title, name: "1" },
                      })
                    : _vm._e(),
                  _vm.tabs[2].isShow
                    ? _c("el-tab-pane", {
                        attrs: { label: _vm.tabs[2].title, name: "2" },
                      })
                    : _vm._e(),
                  _vm.tabs[3].isShow
                    ? _c("el-tab-pane", {
                        attrs: { label: _vm.tabs[3].title, name: "3" },
                      })
                    : _vm._e(),
                  _vm.tabs[4].isShow
                    ? _c("el-tab-pane", {
                        attrs: { label: _vm.tabs[4].title, name: "4" },
                      })
                    : _vm._e(),
                  _vm.tabs[5].isShow
                    ? _c("el-tab-pane", {
                        attrs: { label: _vm.tabs[5].title, name: "5" },
                      })
                    : _vm._e(),
                  _vm.tabs[6].isShow
                    ? _c("el-tab-pane", {
                        attrs: { label: _vm.tabs[6].title, name: "6" },
                      })
                    : _vm._e(),
                  _vm.tabs[7].isShow
                    ? _c("el-tab-pane", {
                        attrs: { label: _vm.tabs[7].title, name: "7" },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          ref: "scrollContent",
          staticClass: "scroll-content recordContent",
          style:
            "overflow-x: hidden; overflow-y: auto;height:" +
            _vm.contentStyleObj.height,
          on: { scroll: _vm.onScroll },
        },
        [
          _c(
            "el-row",
            { staticClass: "substanceName" },
            [
              _c(
                "el-col",
                {
                  staticStyle: { "padding-left": "15px" },
                  attrs: { span: 24 },
                },
                [
                  _c(
                    "el-popover",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.substanceData.dkbReferenceInfos.length > 0,
                          expression:
                            "substanceData.dkbReferenceInfos.length>0",
                        },
                      ],
                      attrs: {
                        placement: "top",
                        title: "来源引用信息",
                        width: "200",
                        trigger: "click",
                      },
                    },
                    [
                      _c(
                        "div",
                        _vm._l(
                          _vm.substanceData.dkbReferenceInfos,
                          function (item) {
                            return _c(
                              "p",
                              { key: "substanceNameReferenceInfos" + item.id },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s("[" + item.resourceTypeName + "]") +
                                    _vm._s(item.resourceName || "") +
                                    " "
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                      _c("i", {
                        staticClass: "el-icon-info",
                        attrs: { slot: "reference", title: "点击查看来源引用" },
                        slot: "reference",
                      }),
                    ]
                  ),
                  _c("span", { staticStyle: { "margin-right": "5px" } }, [
                    _vm._v(
                      _vm._s(_vm.substanceData.commonName) +
                        "(id:" +
                        _vm._s(_vm.substanceData.substanceId) +
                        ")"
                    ),
                  ]),
                  _vm.substanceData.typeIcon
                    ? _c("code-icon", {
                        attrs: {
                          iconCode: _vm.substanceData.typeIcon,
                          size: 26,
                        },
                      })
                    : _vm._e(),
                  _vm._l(_vm.classifyIcons, function (item, index) {
                    return _c("code-icon", {
                      key: index,
                      staticStyle: { "margin-left": "5px" },
                      attrs: { iconCode: item, size: 26 },
                    })
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { ref: _vm.tabs[0].refName, staticClass: "scroll-item" },
            [
              _c(
                "el-row",
                { staticClass: "substanceStructure" },
                [
                  _c(
                    "el-col",
                    { staticClass: "substanceTitle", attrs: { span: 24 } },
                    [_c("span", [_vm._v("物质结构")])]
                  ),
                  _c("el-form", { staticStyle: { padding: "0px 20px" } }, [
                    _vm.substanceData.dkbSubstanceStructure
                      ? _c("div", { staticClass: "wrap" }, [
                          _c("div", { attrs: { id: "left" } }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  background: "#EBF0FA",
                                  border: "1px solid #BDD3FF",
                                  width: "200px",
                                  height: "200px",
                                },
                              },
                              [
                                !_vm.substanceData.dkbSubstanceStructure
                                  .structuralPicture
                                  ? _c("img", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%",
                                      },
                                      attrs: {
                                        src: require("@/assets/images/no-image.jpg"),
                                        alt: "image",
                                      },
                                    })
                                  : _vm._e(),
                                _vm.substanceData.dkbSubstanceStructure
                                  .structuralPicture
                                  ? _c("img", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%",
                                      },
                                      attrs: {
                                        src:
                                          "data:image/png;base64," +
                                          _vm.substanceData
                                            .dkbSubstanceStructure
                                            .structuralPicture,
                                        alt: "image",
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                          _c("div", { attrs: { id: "right" } }, [
                            _vm.substanceData.dkbSubstanceStructure
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "min-height": "200px",
                                      background: "#EBF0FA",
                                      border: "1px dashed #BDD3FF",
                                      "padding-left": "20px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 12 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "立体化学:" } },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.substanceData
                                                        .dkbSubstanceStructure
                                                        .stereochemistry
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 12 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "分子式:" } },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.substanceData
                                                        .dkbSubstanceStructure
                                                        .molecularFormula
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 12 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "旋光性:" } },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.substanceData
                                                        .dkbSubstanceStructure
                                                        .opticalActivity
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 12 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "分子量:" } },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.substanceData
                                                        .dkbSubstanceStructure
                                                        .molecularWeight
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 20 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "SMILES:" } },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.substanceData
                                                        .dkbSubstanceStructure
                                                        .smiles
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 20 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: { label: "INCHI_KEY:" },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.substanceData
                                                        .dkbSubstanceStructure
                                                        .inchiKey
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 20 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "INCHI:" } },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.substanceData
                                                        .dkbSubstanceStructure
                                                        .inchi
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { ref: _vm.tabs[1].refName, staticClass: "scroll-item" },
            [
              _c(
                "el-row",
                { staticClass: "substanceStructure" },
                [
                  _c(
                    "el-col",
                    { staticClass: "substanceTitle", attrs: { span: 24 } },
                    [_c("span", [_vm._v("物质别名")])]
                  ),
                  _vm.substanceData.otherNameS.length > 0
                    ? _c(
                        "el-col",
                        { staticStyle: { "padding-left": "30px" } },
                        [
                          _c(
                            "ul",
                            _vm._l(
                              _vm.substanceData.otherNameS,
                              function (item) {
                                return _c(
                                  "li",
                                  { staticClass: "substanceLi" },
                                  [
                                    _c(
                                      "el-popover",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              item.dkbReferenceInfos.length > 0,
                                            expression:
                                              "item.dkbReferenceInfos.length>0",
                                          },
                                        ],
                                        attrs: {
                                          placement: "top",
                                          title: "来源引用信息",
                                          width: "200",
                                          trigger: "click",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          _vm._l(
                                            item.dkbReferenceInfos,
                                            function (j) {
                                              return _c(
                                                "p",
                                                {
                                                  key:
                                                    "substanceNameReferenceInfos" +
                                                    j.id,
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        "[" +
                                                          j.resourceTypeName +
                                                          "]"
                                                      ) +
                                                      _vm._s(
                                                        j.resourceName || ""
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                        _c("i", {
                                          staticClass: "el-icon-info",
                                          attrs: {
                                            slot: "reference",
                                            title: "点击查看来源引用",
                                          },
                                          slot: "reference",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-right": "5px" },
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    ),
                                    _c("code-icon", {
                                      attrs: {
                                        iconCode: item.nameLangueIcon,
                                        size: 25,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      )
                    : _c("el-col", [
                        _c("span", { staticClass: "noDataClass" }, [
                          _vm._v("暂无数据"),
                        ]),
                      ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { ref: _vm.tabs[2].refName, staticClass: "scroll-item" },
            [
              _c(
                "el-row",
                { staticClass: "substanceStructure" },
                [
                  _c(
                    "el-col",
                    { staticClass: "substanceTitle", attrs: { span: 24 } },
                    [_c("span", [_vm._v("物质编码")])]
                  ),
                  _vm.substanceData.dkbSubstanceCode.length > 0
                    ? _c(
                        "el-col",
                        { staticStyle: { "padding-left": "30px" } },
                        [
                          _c(
                            "ul",
                            _vm._l(
                              _vm.substanceData.dkbSubstanceCode,
                              function (item, index) {
                                return _c(
                                  "li",
                                  { key: index, staticClass: "substanceLi" },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(item.codeSysName.toUpperCase()) +
                                          "：" +
                                          _vm._s(item.substanceCode)
                                      ),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      )
                    : _c("el-col", [
                        _c("span", { staticClass: "noDataClass" }, [
                          _vm._v("暂无数据"),
                        ]),
                      ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { ref: _vm.tabs[3].refName, staticClass: "scroll-item" },
            [
              _c(
                "el-row",
                { staticClass: "substanceStructure" },
                [
                  _c(
                    "el-col",
                    { staticClass: "substanceTitle", attrs: { span: 24 } },
                    [_c("span", [_vm._v("物质属性")])]
                  ),
                  _vm.substanceData.dkbSubstanceProps.length > 0
                    ? _c(
                        "el-col",
                        { staticStyle: { "padding-left": "30px" } },
                        [
                          _c(
                            "ul",
                            _vm._l(
                              _vm.substanceData.dkbSubstanceProps,
                              function (item, index) {
                                return _c(
                                  "li",
                                  { key: index, staticClass: "substanceLi" },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(item.nameDesc) +
                                          "：" +
                                          _vm._s(item.nameDesc)
                                      ),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      )
                    : _c("el-col", [
                        _c("span", { staticClass: "noDataClass" }, [
                          _vm._v("暂无数据"),
                        ]),
                      ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { ref: _vm.tabs[4].refName, staticClass: "scroll-item" },
            [
              _c(
                "el-row",
                { staticClass: "substanceStructure" },
                [
                  _c(
                    "el-col",
                    { staticClass: "substanceTitle", attrs: { span: 24 } },
                    [_c("span", [_vm._v("物质分类")])]
                  ),
                  _vm.typeDataList.length > 0
                    ? _c(
                        "el-col",
                        {
                          staticStyle: { "margin-left": "20px" },
                          attrs: { span: 24 },
                        },
                        _vm._l(_vm.typeDataList, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "fl classify-li",
                              staticStyle: {
                                display: "inline-block",
                                margin: "0 0px 5px 10px",
                              },
                            },
                            [
                              _c("el-tree", {
                                staticClass: "classify-tree",
                                attrs: {
                                  data: item,
                                  props: _vm.defaultProps,
                                  "empty-text": "暂无数据",
                                  "node-key": "id",
                                  "expand-on-click-node": false,
                                  "default-expand-all": "",
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _c("el-col", [
                        _c("span", { staticClass: "noDataClass" }, [
                          _vm._v("暂无数据"),
                        ]),
                      ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { ref: _vm.tabs[5].refName, staticClass: "scroll-item" },
            [
              _c(
                "el-row",
                { staticClass: "substanceStructure" },
                [
                  _c(
                    "el-col",
                    { staticClass: "substanceTitle", attrs: { span: 24 } },
                    [
                      _c("span", [
                        _vm._v("含本物质"),
                        _c("span", { staticClass: "redName" }, [
                          _vm._v("药物"),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "substanceTable",
                      staticStyle: { width: "1000px" },
                    },
                    [
                      _c(
                        "el-table",
                        {
                          attrs: {
                            data: _vm.druglistData,
                            border: "",
                            stripes: "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "名称",
                              width: "300",
                              align: "left",
                              "header-align": "left",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return _vm._l(
                                    scope.row.dkbDrugAliasList,
                                    function (item, index) {
                                      return _c("span", { key: index }, [
                                        item.isPrimary === "1"
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "linkToInfo",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.toDrugInfo(item)
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.alias) + " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ])
                                    }
                                  )
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "classifyDesc",
                              label: "类别",
                              width: "100",
                              align: "center",
                              "header-align": "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "",
                              label: "成分",
                              align: "left",
                              "header-align": "left",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return _vm._l(
                                    scope.row.dkbDrugIngredientList,
                                    function (item, index) {
                                      return _c(
                                        "span",
                                        { key: index },
                                        [
                                          index !== 0
                                            ? _c("span", [_vm._v("+")])
                                            : _vm._e(),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "linkToInfo",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.toSubstanceInfo(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.substanceName) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c("code-icon", {
                                            attrs: {
                                              iconCode: item.substanceTypeIcon,
                                              size: 25,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { ref: _vm.tabs[6].refName, staticClass: "scroll-item" },
            [
              _c(
                "el-row",
                { staticClass: "substanceStructure" },
                [
                  _c(
                    "el-col",
                    { staticClass: "substanceTitle", attrs: { span: 24 } },
                    [
                      _c("span", [
                        _vm._v("含本物质"),
                        _c("span", { staticClass: "redName" }, [
                          _vm._v("药品"),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "substanceTable",
                      staticStyle: { width: "1000px" },
                    },
                    [
                      _c(
                        "el-table",
                        {
                          attrs: {
                            data: _vm.drugProdlistData,
                            border: "",
                            stripes: "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "名称",
                              align: "left",
                              width: "300",
                              "header-align": "left",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return _vm._l(
                                    scope.row.dkbDpAliasList,
                                    function (item, index) {
                                      return _c("span", { key: index }, [
                                        item.isPrimary === "1"
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "linkToInfo",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.toDrugProdInfo(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.alias) + " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ])
                                    }
                                  )
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "",
                              label: "成分",
                              align: "left",
                              "header-align": "left",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return _vm._l(
                                    scope.row.dkbDpSpecIngredientList,
                                    function (item, index) {
                                      return _c("span", { key: index }, [
                                        index !== 0
                                          ? _c("span", [_vm._v("+")])
                                          : _vm._e(),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "linkToInfo",
                                            on: {
                                              click: function ($event) {
                                                return _vm.toSubstanceInfo(item)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.substanceName) +
                                                " "
                                            ),
                                            item.substanceQtyMax
                                              ? _c(
                                                  "span",
                                                  { staticClass: "unit-link" },
                                                  [
                                                    _vm._v(
                                                      "（" +
                                                        _vm._s(
                                                          item.substanceQtyMax
                                                        )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            item.substanceQtyMax
                                              ? _c(
                                                  "span",
                                                  { staticClass: "unit-link" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.substanceQtyUnitDesc
                                                      ) + "）"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ])
                                    }
                                  )
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "dkbPpInfo.preparationForm",
                              label: "剂型",
                              width: "100",
                              align: "center",
                              "header-align": "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "规格包装",
                              width: "150",
                              align: "left",
                              "header-align": "left",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return _vm._l(
                                    scope.row.dkbPdProdList,
                                    function (item, index) {
                                      return _c("span", { key: index }, [
                                        scope.row.dkbPpInfo.showSpec
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.dkbPpInfo.showSpec
                                                )
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(
                                          " * " +
                                            _vm._s(item.packQty) +
                                            " " +
                                            _vm._s(item.specUnitDesc) +
                                            " "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "18px",
                                              "font-weight": "bold",
                                            },
                                          },
                                          [_vm._v("/")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(item.packQtyUnitDesc) +
                                            " "
                                        ),
                                        index !==
                                        scope.row.dkbPdProdList.length - 1
                                          ? _c("span", [_vm._v("，")])
                                          : _vm._e(),
                                        _c("br"),
                                      ])
                                    }
                                  )
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "生产厂家",
                              width: "180",
                              align: "left",
                              "header-align": "left",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return _vm._l(
                                    scope.row.dkbDpManufacturerList,
                                    function (item, index) {
                                      return _c("span", { key: index }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.manufacturerName) +
                                            " "
                                        ),
                                        index !==
                                        scope.row.dkbDpManufacturerList.length -
                                          1
                                          ? _c("span", [_vm._v("，")])
                                          : _vm._e(),
                                        _c("br"),
                                      ])
                                    }
                                  )
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { ref: _vm.tabs[7].refName, staticClass: "scroll-item" },
            [
              _c(
                "el-row",
                { staticClass: "substanceStructure" },
                [
                  _c(
                    "el-col",
                    { staticClass: "substanceTitle", attrs: { span: 24 } },
                    [
                      _c("span", [
                        _vm._v("含本物质"),
                        _c("span", { staticClass: "redName" }, [
                          _vm._v("制剂"),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "substanceTable",
                      staticStyle: { width: "1000px" },
                    },
                    [
                      _c(
                        "el-table",
                        {
                          attrs: {
                            data: _vm.specProdlistData,
                            border: "",
                            stripes: "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "名称",
                              align: "left",
                              width: "300",
                              "header-align": "left",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "linkToInfo",
                                        on: {
                                          click: function ($event) {
                                            return _vm.toPreparationInfo(
                                              scope.row.preparationId
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(scope.row.preparationAlias)
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "typeName",
                              label: "剂型",
                              width: "100",
                              align: "center",
                              "header-align": "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "规格",
                              align: "left",
                              "header-align": "center",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return _vm._l(
                                    scope.row.specList,
                                    function (item, index) {
                                      return _c("span", { key: index }, [
                                        _vm._v(
                                          " " + _vm._s(item.showSpec) + " "
                                        ),
                                        index !== scope.row.specList.length - 1
                                          ? _c("span", [_vm._v("，")])
                                          : _vm._e(),
                                        _c("br"),
                                      ])
                                    }
                                  )
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "routeName",
                              label: "用药途径",
                              width: "100",
                              align: "center",
                              "header-align": "center",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }