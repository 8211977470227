<template>
  <div class="custom-demo-view" style="padding: 1rem;">
    <div style="background: #FFFFFF;padding-left: 20px;margin-bottom: 10px;">
      <el-tabs v-if="reload" v-model="tabIndex" @tab-click="jump">
        <el-tab-pane :label="tabs[0].title" v-if="tabs[0].isShow" name="0"></el-tab-pane>
        <el-tab-pane :label="tabs[1].title" v-if="tabs[1].isShow" name="1"></el-tab-pane>
        <el-tab-pane :label="tabs[2].title" v-if="tabs[2].isShow" name="2"></el-tab-pane>
        <el-tab-pane :label="tabs[3].title" v-if="tabs[3].isShow" name="3"></el-tab-pane>
        <el-tab-pane :label="tabs[4].title" v-if="tabs[4].isShow" name="4"></el-tab-pane>
        <el-tab-pane :label="tabs[5].title" v-if="tabs[5].isShow" name="5"></el-tab-pane>
        <el-tab-pane :label="tabs[6].title" v-if="tabs[6].isShow" name="6"></el-tab-pane>
        <el-tab-pane :label="tabs[7].title" v-if="tabs[7].isShow" name="7"></el-tab-pane>
      </el-tabs>
    </div>
    <div ref="scrollContent" class="scroll-content recordContent" @scroll="onScroll" :style="'overflow-x: hidden; overflow-y: auto;height:' + contentStyleObj.height">
      <el-row class="substanceName">
        <el-col :span="24" style="padding-left:15px;">
          <el-popover v-show="substanceData.dkbReferenceInfos.length>0" placement="top" title="来源引用信息"
                         width="200" trigger="click"
             >
               <div>
                 <p v-for="item in substanceData.dkbReferenceInfos"
                    :key="'substanceNameReferenceInfos'+item.id"
                 >
                   {{ '[' + item.resourceTypeName + ']' }}{{ item.resourceName || '' }}
                 </p>
               </div>
            <i class="el-icon-info" slot="reference" title="点击查看来源引用"></i>
          </el-popover>
          <span style="margin-right: 5px;">{{ substanceData.commonName }}(id:{{ substanceData.substanceId }})</span>
          <code-icon v-if="substanceData.typeIcon"  :iconCode="substanceData.typeIcon"  :size="26"/>
          <code-icon
            v-for="(item, index) in classifyIcons"
            :key="index"
            :iconCode="item"
            :size="26"
            style="margin-left: 5px;" />
        </el-col>
      </el-row>

      <!-- 物质结构 -->
      <div :ref="tabs[0].refName" class="scroll-item">
        <el-row class="substanceStructure">
          <el-col :span="24" class="substanceTitle">
           <span>物质结构</span>
          </el-col>
          <el-form style="padding: 0px 20px;">
            <div class="wrap" v-if="substanceData.dkbSubstanceStructure">
              <div id="left">
                <div style="background: #EBF0FA;border: 1px solid #BDD3FF;width: 200px;height: 200px;">
                    <img src="@/assets/images/no-image.jpg" alt="image" style="width: 100%;height: 100%;"
                         v-if="!substanceData.dkbSubstanceStructure.structuralPicture">
                    <img :src="'data:image/png;base64,'+substanceData.dkbSubstanceStructure.structuralPicture"
                         alt="image" style="width: 100%;height: 100%"
                         v-if="substanceData.dkbSubstanceStructure.structuralPicture">
                  </div>
              </div>
              <div id="right">
                <div style="min-height: 200px;background: #EBF0FA;border: 1px dashed #BDD3FF;padding-left:20px;" v-if="substanceData.dkbSubstanceStructure">
                  <el-row>
                    <el-col :span="12">
                      <el-form-item label="立体化学:">
                        {{ substanceData.dkbSubstanceStructure.stereochemistry }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="分子式:">
                        {{ substanceData.dkbSubstanceStructure.molecularFormula }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <el-form-item label="旋光性:">
                        {{ substanceData.dkbSubstanceStructure.opticalActivity }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="分子量:">
                        {{ substanceData.dkbSubstanceStructure.molecularWeight }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row >
                    <el-col :span="20">
                      <el-form-item label="SMILES:">
                        {{ substanceData.dkbSubstanceStructure.smiles }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="20">
                      <el-form-item label="INCHI_KEY:">
                        {{ substanceData.dkbSubstanceStructure.inchiKey }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="20">
                      <el-form-item label="INCHI:">
                        {{ substanceData.dkbSubstanceStructure.inchi }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </el-form>
        </el-row>
      </div>
      <!-- 物质别名 -->
      <div :ref="tabs[1].refName" class="scroll-item">
        <el-row class="substanceStructure">
          <el-col :span="24" class="substanceTitle">
            <span>物质别名</span>
          </el-col>
          <el-col style="padding-left:30px;" v-if="substanceData.otherNameS.length>0">
            <ul>
              <li class="substanceLi" v-for="item in substanceData.otherNameS">
                <el-popover v-show="item.dkbReferenceInfos.length>0" placement="top" title="来源引用信息"
                            width="200" trigger="click"
                >
                  <div>
                    <p v-for="j in item.dkbReferenceInfos"
                       :key="'substanceNameReferenceInfos'+j.id"
                    >
                      {{ '[' + j.resourceTypeName + ']' }}{{ j.resourceName || '' }}
                    </p>
                  </div>
                  <i class="el-icon-info" slot="reference" title="点击查看来源引用"></i>
                </el-popover>
<!--                <i class="el-icon-info" title="点击查看来源引用"></i>-->
                <span style="margin-right: 5px;">{{ item.name }}</span>
                <code-icon  :iconCode="item.nameLangueIcon"  :size="25"/>
<!--                <svg-icon v-show="item.nameLangue === 100001" icon-class="china" style="width: 24px;height: 16px;"/>-->
<!--                <svg-icon v-show="item.nameLangue === 100002" icon-class="yingguoguoqi" style="width: 24px;height: 16px;"/>-->
              </li>
            </ul>
          </el-col>
          <el-col v-else>
            <span class="noDataClass">暂无数据</span>
          </el-col>
        </el-row>
      </div>
      <!-- 物质编码 -->
      <div :ref="tabs[2].refName" class="scroll-item">
        <el-row class="substanceStructure">
          <el-col :span="24" class="substanceTitle">
            <span>物质编码</span>
          </el-col>
          <el-col style="padding-left:30px;" v-if="substanceData.dkbSubstanceCode.length>0">
            <ul>
              <li class="substanceLi" v-for="(item,index) in substanceData.dkbSubstanceCode" :key="index">
                <span>{{ item.codeSysName.toUpperCase() }}：{{ item.substanceCode }}</span>
              </li>
            </ul>
          </el-col>
          <el-col v-else>
            <span class="noDataClass">暂无数据</span>
          </el-col>
        </el-row>
      </div>
      <!-- 物质属性 -->
      <div :ref="tabs[3].refName" class="scroll-item">
        <el-row class="substanceStructure">
          <el-col :span="24" class="substanceTitle">
            <span>物质属性</span>
          </el-col>
          <el-col style="padding-left:30px;" v-if="substanceData.dkbSubstanceProps.length>0">
            <ul>
              <li class="substanceLi" v-for="(item,index) in substanceData.dkbSubstanceProps" :key="index">
                <span>{{ item.nameDesc }}：{{ item.nameDesc }}</span>
              </li>
            </ul>
          </el-col>
          <el-col v-else>
            <span class="noDataClass">暂无数据</span>
          </el-col>
        </el-row>
      </div>
      <!-- 物质分类 -->
      <div :ref="tabs[4].refName" class="scroll-item">
        <el-row class="substanceStructure">
            <el-col :span="24" class="substanceTitle">
              <span>物质分类</span>
            </el-col>
            <el-col :span="24" v-if="typeDataList.length>0" style="margin-left: 20px;">
              <div
                v-for="(item,index) in typeDataList"
                :key="index"
                class="fl classify-li"
                style="display:inline-block;margin: 0 0px 5px 10px;">
<!--                float:left;-->
<!--                <treeselect-->
<!--                  style="width: 200px;"-->
<!--                  :defaultExpandLevel="Infinity"-->
<!--                  :value="item[0].valueId"-->
<!--                  :options="item"-->
<!--                  :normalizer="normalizerTreeNode"-->
<!--                  :disable-branch-nodes="true"-->
<!--                  :show-count="true"-->
<!--                  :placeholder="'查看第'+String(index+1)+'个物质分类'"-->
<!--                />-->
                <el-tree
                  :data="item"
                  :props="defaultProps"
                  empty-text="暂无数据"
                  node-key="id"
                  class="classify-tree"
                  :expand-on-click-node="false"
                  default-expand-all>
                </el-tree>
              </div>
            </el-col>
            <el-col v-else>
              <span class="noDataClass">暂无数据</span>
            </el-col>
          </el-row>
      </div>
      <!-- 含本物质药物 -->
      <div :ref="tabs[5].refName" class="scroll-item">
        <el-row class="substanceStructure">
          <el-col :span="24" class="substanceTitle">
            <span>含本物质<span class="redName">药物</span></span>
          </el-col>
          <el-col class="substanceTable" style="width: 1000px">
            <el-table
              :data="druglistData"
              border
              stripes>
              <el-table-column
                label="名称"
                width="300"
                align="left"
                header-align="left">
                <template slot-scope="scope">
                  <span
                    v-for="(item,index) in scope.row.dkbDrugAliasList" :key="index" >
                    <span v-if="item.isPrimary === '1'" class="linkToInfo" @click="toDrugInfo(item)">
                      {{item.alias}}
                    </span>
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="classifyDesc"
                label="类别"
                width="100"
                align="center"
                header-align="center">
              </el-table-column>
              <el-table-column
                prop=""
                label="成分"
                align="left"
                header-align="left">
                <template slot-scope="scope">
                  <span v-for="(item,index) in scope.row.dkbDrugIngredientList" :key="index">
                    <span v-if="index!== 0">+</span>
                    <span class="linkToInfo" @click="toSubstanceInfo(item)">
                      {{item.substanceName}}
                    </span>
                    <code-icon  :iconCode="item.substanceTypeIcon"  :size="25"/>
<!--                    <i v-if="item.substanceType===100501" class="live">活</i>-->
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
      <!-- 含本物质药品 -->
      <div :ref="tabs[6].refName" class="scroll-item">
        <el-row class="substanceStructure">
          <el-col :span="24" class="substanceTitle">
            <span>含本物质<span class="redName">药品</span></span>
          </el-col>
          <el-col class="substanceTable" style="width: 1000px">
            <el-table
              :data="drugProdlistData"
              border
              stripes>
              <el-table-column
                label="名称"
                align="left"
                width="300"
                header-align="left">
                <template slot-scope="scope">
                  <span
                    v-for="(item,index) in scope.row.dkbDpAliasList" :key="index" >
                    <span v-if="item.isPrimary === '1'" class="linkToInfo" @click="toDrugProdInfo(item)">
                      {{ item.alias }}
                    </span>
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop=""
                label="成分"
                align="left"
                header-align="left">
                <template slot-scope="scope">
                  <span v-for="(item,index) in scope.row.dkbDpSpecIngredientList" :key="index">
                    <span v-if="index!== 0">+</span>
                    <span class="linkToInfo" @click="toSubstanceInfo(item)">
                      {{item.substanceName}}
                      <span class="unit-link" v-if="item.substanceQtyMax">（{{ item.substanceQtyMax }}</span>
                      <span class="unit-link" v-if="item.substanceQtyMax">{{ item.substanceQtyUnitDesc }}）</span>
                    </span>
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="dkbPpInfo.preparationForm"
                label="剂型"
                width="100"
                align="center"
                header-align="center">
              </el-table-column>
<!--              <el-table-column-->
<!--                prop="dkbPpInfo.routeName"-->
<!--                label="用药途径"-->
<!--                width="150">-->
<!--              </el-table-column>-->
              <el-table-column
                label="规格包装"
                width="150"
                align="left"
                header-align="left">
                <template slot-scope="scope">
                  <span v-for="(item,index) in scope.row.dkbPdProdList" :key="index">
                      <span v-if="scope.row.dkbPpInfo.showSpec">{{ scope.row.dkbPpInfo.showSpec }}</span>
                      *
                     {{ item.packQty }}
                  {{ item.specUnitDesc }}
                  <span style="font-size: 18px;font-weight: bold;">/</span>
                  {{ item.packQtyUnitDesc }}
                    <span v-if="index!== scope.row.dkbPdProdList.length -1 ">，</span>
                    <br/>
                  </span>

                </template>
              </el-table-column>
              <el-table-column
                label="生产厂家"
                width="180"
                align="left"
                header-align="left">
                <template slot-scope="scope">
                  <span v-for="(item,index) in scope.row.dkbDpManufacturerList" :key="index">
                    {{ item.manufacturerName }}
                    <span v-if="index !== scope.row.dkbDpManufacturerList.length -1 ">，</span>
                    <br/>
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
      <!-- 含本物质制剂 -->
      <div :ref="tabs[7].refName" class="scroll-item">
        <el-row class="substanceStructure">
          <el-col :span="24" class="substanceTitle">
            <span>含本物质<span class="redName">制剂</span></span>
          </el-col>
          <el-col class="substanceTable" style="width:1000px">
            <el-table
              :data="specProdlistData"
              border
              stripes>
              <el-table-column
                label="名称"
                align="left"
                width="300"
                header-align="left">
                <template slot-scope="scope">
                  <span class="linkToInfo" @click="toPreparationInfo(scope.row.preparationId)">{{ scope.row.preparationAlias }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="typeName"
                label="剂型"
                width="100"
                align="center"
                header-align="center">
              </el-table-column>
              <el-table-column
                label="规格"
                align="left"
                header-align="center">
                <template slot-scope="scope">
                  <span v-for="(item,index) in scope.row.specList" :key="index">

                     {{ item.showSpec }}
                    <span v-if="index!== scope.row.specList.length - 1">，</span>
                    <br/>
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="routeName"
                label="用药途径"
                width="100"
                align="center"
                header-align="center">
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import {
  info,
  drugSelectBySubstanceId,
  drugProdSelectBySubstanceId,
  preparationList,
  getDrugCategoryTree
} from '@/api/dkm/substance'
export default {
  name: 'SubstanceSearch',
  components: {Treeselect },
  data() {
    return {
      classifyIcons: [], // 分类图标
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      typeValue: null,
      reload: true,
      tabIndex: '0',
      contentStyleObj: {
        height: '100px'
      },
      tabs: [
        {
          isShow: true,
          title: '物质结构',
          refName: 'setOneRef'
        },
        {
          isShow: true,
          title: '物质别名',
          refName: 'setTwoRef'
        },
        {
          isShow: true,
          title: '物质编码',
          refName: 'setThreeRef'
        },
        {
          isShow: true,
          title: '物质属性',
          refName: 'setFourRef'
        },
        {
          isShow: true,
          title: '物质分类',
          refName: 'setFiveRef'
        },
        {
          isShow: true,
          title: '含本物质药物',
          refName: 'setSixRef'
        },
        {
          isShow: true,
          title: '含本物质药品',
          refName: 'setSevenRef'
        },
        {
          isShow: true,
          title: '含本物质制剂',
          refName: 'setEightRef'
        }
      ],
      typeDataList: [],
      substanceData: {
        dkbReferenceInfos: [],
        dkbSubstanceStructure: {
          structuralPicture:null
        },
        otherNameS: [],
        dkbSubstanceCode: [],
        dkbSubstanceProps: []
      }, // 物质info
      druglistData: [], // 含本物质药物
      drugProdlistData: [], // 含本物质药品
      specProdlistData: [], // 含本物质制剂
      scrollY: 0
    }
  },
  activated() {
    this.classifyIcons = []
    this.getHight()
    window.addEventListener('resize', this.getHight)
    const id = this.$route.params && this.$route.params.id
    if (id) {
      this.getSubstanceInfo(id)
      this.drugSelectBySubstanceId(id)
      this.drugProdSelectBySubstanceId(id)
      this.preparationList(id)
      this.getDrugCategoryTree(id)
    }
    this.$nextTick(()=> {
      document.querySelector('.recordContent').scrollTop = this.scrollY
    })
  },
  beforeRouteEnter (to, from, next) {
    if (to.name === 'SubstanceSearch') {
      next(vm => {
        const scrollContent = vm.$refs.scrollContent
        // 记录滚动高度
        scrollContent.scrollTop = vm.$route.meta.scroll || 0
        vm.scrollY = vm.$route.meta.scroll
      })
    } else {
      next(vm => {
        const scrollContent = vm.$refs.scrollContent
        // 不记录滚动高度
        scrollContent.scrollTop = 0
      })
    }
  },
  beforeRouteLeave (to, from, next) {
    if (from.name === 'SubstanceSearch') {
      const scrollContent = this.$refs.scrollContent
      this.$route.meta.scroll = scrollContent.scrollTop
    }
    next()
  },
  methods: {
    // 跳转到药物查看页面
    toDrugInfo(item){
      const durgId = item.drugId
      this.$router.push('/drug/search/' + durgId)
    },
    // 跳转到物质查看页面
    toSubstanceInfo(item){
      const substanceId = item.substanceId
      this.$router.push('/substance/search/' + substanceId)
    },
    // 跳转到药品查看页面
    toDrugProdInfo(item){
      const drugProdId = item.drugProdId
      this.$router.push('/drugProd/view/' + drugProdId)
    },
    // 跳转到制剂查看页面
    toPreparationInfo(val){
      const preparationId = val
      this.$router.push('/preparationSpecification/view/' + preparationId)
    },
    /** 转换剂型树形数据结构 */
    normalizerTreeNode(node) {
      if (!(node.children && node.children.length > 0)) {
        delete node.children
      }

      return {
        id: node.id,
        label: node.name,
        children: node.children
      }
    },
    /*根据物质id获取物质明细*/
    getSubstanceInfo(id) {
      var that = this
      info(id).then(response => {
        this.substanceData = response.data
        // 通用名 isPrimary=1
        this.substanceData.dkbSubstanceName.map((item) => {
          if(item.isPrimary === '1') {
            this.substanceData.dkbReferenceInfos  = item.dkbReferenceInfos
            this.substanceData.commonName = item.name
          }
        })
        // 别名 isPrimary != 1
        let otherNameS = []
        this.substanceData.dkbSubstanceName.map((item) => {
          if(item.isPrimary !== '1') {
            otherNameS.push(item)
            // this.substanceData.commonName = item.name
          }
        })
        this.substanceData.otherNameS = otherNameS
      })

    },
    // 含本物质药物
    drugSelectBySubstanceId(id) {
      let obj = {}
      obj.pageNum = 1
      obj.pageSize = 100000
      obj.substanceId = id
      drugSelectBySubstanceId(obj).then(response => {
        this.druglistData = response.rows
      })
    },
    // 药品:根据物质id查询含有该物质的药品列表
    drugProdSelectBySubstanceId(id) {
      let obj = {}
      obj.pageNum = 1
      obj.pageSize = 100000
      obj.substanceId = id
      drugProdSelectBySubstanceId(obj).then(response => {
        this.drugProdlistData = response.rows
      })
    },
    preparationList(id) {
      let obj = {}
      obj.pageNum = 1
      obj.pageSize = 100000
      obj.substanceId = id
      preparationList(obj).then(response => {
        this.specProdlistData = response.data.rows
      })
    },
    getDrugCategoryTree(id) {
      let obj = {}
      obj.dataType = 1
      obj.dataId = id
      getDrugCategoryTree(obj).then(response => {
        let typeDataList = response.data || []
        let arr = this.classifyDataHandle(typeDataList)
        this.typeDataList = arr
        this.classifyIcons = [...new Set(this.classifyIcons)]
      })
    },
    // 处理分类的数据
    classifyDataHandle(typeDataList) {
      let arr = []
      for (let item of typeDataList) {
        if (!item.children) {
          item.children = []
          item.label = item.name
          item.value = item.id
          item.valueId = item.id
          this.classifyIcons.push(item.icon)
        } else if (item.children && item.children.length > 0) {
          this.treeselectDeep(item, item.children)
        }
        arr.push([item])
      }
      return arr
    },
    treeselectDeep(parent, item) {
      if (Array.isArray(item)) {
        // 数组
        for (let i = 0; i < item.length; i++) {
          if (item[i].children) {
            this.treeselectDeep(item[i], item[i].children)
            parent.valueId = item[i].valueId
            parent.label = item[i].label
          } else {
            parent.valueId = item[i].id
            parent.label = item[i].name
            item[i].icon && this.classifyIcons.push(item[i].icon)
          }
        }
      } else if (item?.constructor === Object) {
        // 对象
        if (item.children && !Array.isArray(item)) {
          this.a(item, item.children)
        } else if (!item.children && !Array.isArray(item)) {
          parent.valueId = parent.children[0].id
          parent.label = parent.children[0].name
        }
      }
    },
    refresh() {
      this.reload = false
      this.$nextTick(() => (this.reload = true))
    },
    // tab click
    jump(index, info) {
      let target = document.querySelector('.scroll-content')
      let scrollItems = document.querySelectorAll('.scroll-item')
      // 判断滚动条是否滚动到底部
      if (target.scrollHeight <= target.scrollTop + target.clientHeight) {
        this.tabIndex = index.index.toString()
      }
      let totalY = scrollItems[index.index].offsetTop - scrollItems[0].offsetTop // 锚点元素距离其offsetParent(这里是body)顶部的距离(待滚动的距离)
      let distance = document.querySelector('.scroll-content').scrollTop // 滚动条距离滚动区域顶部的距离
      // let distance = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset // 滚动条距离滚动区域顶部的距离(滚动区域为窗口)
      // 滚动动画实现, 使用setTimeout的递归实现平滑滚动，将距离细分为50小段，10ms滚动一次
      // 计算每一小段的距离
      let step = totalY / 50
      if (totalY > distance) {
        smoothDown(document.querySelector('.scroll-content'))
      } else {
        let newTotal = distance - totalY
        step = newTotal / 50
        smoothUp(document.querySelector('.scroll-content'))
      }

      // 参数element为滚动区域
      function smoothDown(element) {
        if (distance < totalY) {
          distance += step
          element.scrollTop = distance
          setTimeout(smoothDown.bind(this, element), 10)
        } else {
          element.scrollTop = totalY
        }
      }

      // 参数element为滚动区域
      function smoothUp(element) {
        if (distance > totalY) {
          distance -= step
          element.scrollTop = distance
          setTimeout(smoothUp.bind(this, element), 10)
        } else {
          element.scrollTop = totalY
        }
      }
    },
    // 滚动条滚动
    onScroll(e) {
      let scrollItems = document.querySelectorAll('.scroll-item')
      for (let i = scrollItems.length - 1; i >= 0; i--) {
        // 判断滚动条滚动距离是否大于当前滚动项可滚动距离
        let judge = e.target.scrollTop >= scrollItems[i].offsetTop - scrollItems[0].offsetTop - 100
        if (judge) {
          this.tabIndex = i.toString()
          break
        }
      }
    },
    getHight() {
      this.contentStyleObj.height = (window.innerHeight - 190) + 'px'
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.getHight)
  },
}
</script>
<style lang="scss">
.custom-demo-view {
  background: #EBEFF7;
  .is-left{
    padding-left: 5px!important;
  }
  .el-form-item{
    line-height: 20px!important;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    margin-bottom: 0px!important;
  }

  .el-form-item__label{
    line-height: 30px!important;
  }
  .el-form-item__content{
    line-height: 30px!important;
  }
  .el-tabs__header{
    margin-bottom: 0px!important;
  }
  .el-row {
    &:last-child {
      margin-bottom: 0;
    }
  }
  .grid-content{
    border-radius: 4px;
    min-height: 36px;
    background: #ffcc66;
  }
  .substanceImg{
    //.el-row {
    margin-bottom: 20px!important;
    &:last-child {
      margin-bottom: 0!important;
    }
    //}
  }
  .classify-li {
    margin: 10px !important;
    &:first-child {
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
    }
  }
  .classify-tree {
    border: 1px solid #638BF7;
    border-radius: 15px;
    padding: 0 5px;
    min-height: 52px;
  }
  .el-tree-node__content:hover, .el-tree-node__content:focus {
    background-color: transparent !important;
  }
  .el-tree-node__content {
    background-color: transparent !important;
  }
  .el-tree-node .is-current {
    background-color: transparent !important;
  }
}
</style>
<style lang="scss" scoped>
.redName {
  color: red!important;
}
.noDataClass{
  width: 80px;
  height: 30px;
  background: #E6ECFE;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  text-align: center;
  line-height: 30px;
  display: inline-block;
  margin-left: 30px;
}
.linkToInfo{
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  color: #2B60F8;
  cursor: pointer;
}
.unit-link {
  color: #333;
  cursor: pointer;
  text-decoration: underline;
}
.live{
  font-style:normal;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  color: #47BC0D;
  line-height: 22px;
  text-align: center;
  width: 22px;
  height: 22px;
  border: 1px solid #47BC0D;
  border-radius: 11px;
}

.substanceName{
  height: 40px;
  line-height: 40px;
  background: #FFFFFF;
  box-shadow: 1px 1px 6px 0px rgba(202, 202, 202, 0.51);
  border-radius: 4px;
  span{
    font-size: 16px;
    color: #333333;
    font-weight: 600!important;
  }
  //.el-icon-info{
  //  color: #E99B00;
  //  font-size: 16px;
  //  margin:0px 5px 0px 20px;
  //}
}
.el-icon-info{
  color: #E99B00;
  font-size: 16px;
  margin-right:5px;
}
.substanceStructure{
  height: 40px;
  height: auto!important;
  min-height: 40px;
  background: #FFFFFF;
  box-shadow: 1px 1px 6px 0px rgba(202, 202, 202, 0.51);
  border-radius: 4px;
  margin: 10px 0px;
  padding-bottom: 20px;
  .wrap{
    margin: 0 auto;
    width: 100%;
    display: flex;
  }
  #left{
    flex: 0 0 210px; /* 左侧固定200px */
    height: 200px;
    //background: #cccccc;
  }
  #right{
    flex: 1; /* 随父级变化 */
    min-height: 200px;
    height: auto!important;
    height: 200px;
    //background: burlywood;
  }
}
.substanceTitle{
  font-size: 16px;
  font-weight: 600!important;
  padding:16px 0px 5px 19px;
  color: #333333;
}
ul,li{ padding:0;margin:0;list-style:none}
li{
  float: left;
  margin-right: 5px;
  height: 30px;
  line-height: 30px;
  background: #E6ECFE;
  border-radius: 3px;
  padding:0px 10px;
}
.substanceTable{
  padding:10px 20px 0px;
  margin-left:10px;
}
</style>

